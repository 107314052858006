import React from "react";
import { Link } from "gatsby";

import Layout from "@rocketseat/gatsby-theme-docs/src/components/Layout";
import SEO from "@rocketseat/gatsby-theme-docs/src/components/SEO";

export default function NotFound() {
  return (
    <Layout title="Página não encontrada!">
      <SEO title="404: Página não encontrada" />
      <p>Parece que chegámos a um caminho sem saída... Que pena!</p>
      <p>
        Se quiseres voltar à página principal, <Link to="/">carrega aqui</Link>.
      </p>
    </Layout>
  );
}
